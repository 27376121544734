import React from 'react';
import {
  AccountBalanceWallet,
  Add,
  ArrowBack,
  ArrowBackIos,
  ArrowForward,
  ArrowForwardIos,
  ArrowUpward,
  Autorenew,
  Backspace,
  Backup,
  CalendarToday,
  CameraAlt,
  Check,
  CheckCircleRounded,
  Close,
  CloudDone,
  Compare,
  CreditCard,
  DateRange,
  Dialpad,
  Edit,
  EmojiEvents,
  Error,
  ErrorOutline,
  ExpandLess,
  FiberSmartRecord,
  FileCopy,
  FlashOn,
  GetApp,
  History,
  HourglassEmpty,
  Image,
  InsertDriveFile,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LocationOn,
  Loop,
  Map,
  MyLocation,
  NotificationsNone,
  Remove,
  Send,
  Settings,
  ShoppingCart,
  Storefront,
  Style,
  SubdirectoryArrowRight,
  Subject,
  Toll,
  Warning,
  CloudDownload,
  LocalOffer,
  PhoneIphone,
  Launch 
} from '@material-ui/icons';
import { IIconName } from '../interfaces';

const Icons = {
  pay: CreditCard,
  receive: GetApp,
  history: History,
  phone: PhoneIphone, 
  notifications: NotificationsNone,
  ranking: EmojiEvents,
  menu: Subject,
  close: Close,
  flash: FlashOn,
  flip: Loop,
  back: ArrowBack,
  next: ArrowForward,
  check: Check,
  backspace: Backspace,
  add: Add,
  up: ArrowUpward,
  dialpad: Dialpad,
  token: Toll,
  coupen: Style,
  tag: LocalOffer,
  upload: ExpandLess,
  uploading: Autorenew,
  cloud: Backup,
  clouddone: CloudDone,
  fileCopy: FileCopy,
  editIcon: Edit,
  mapIcon: Map,
  walletIcon: AccountBalanceWallet,
  compareIcon: Compare,
  totalSupply: FiberSmartRecord,
  errorIcon: Error,
  dirBackArrow: SubdirectoryArrowRight,
  sellIcon: Storefront,
  sendIcon: Send,
  mintIcon: Toll,
  arrowDown: KeyboardArrowDown,
  arrowUp: KeyboardArrowUp,
  remove: Remove,
  cameraAlt: CameraAlt,
  settings: Settings,
  arrowForward: ArrowForwardIos,
  warning: Warning,
  checkCircleRounded: CheckCircleRounded,
  errorOutline: ErrorOutline,
  defaultImage: Image,
  locationOn: LocationOn,
  myLocation: MyLocation,
  arrowBackIos: ArrowBackIos,
  insertDriveFile: InsertDriveFile,
  dateRange: DateRange,
  shoppingCart: ShoppingCart,
  calendarToday: CalendarToday,
  hourglassEmpty: HourglassEmpty,
  dowload: CloudDownload,
  localOffer: LocalOffer,
  launch: Launch
};

const Icon = (props: IIconName) => {
  const IconTag = Icons[props.name];
  const style = props.style;

  return <IconTag style={style} />;};

export default Icon;
