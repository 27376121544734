const GET_MNEMONIC: string = 'GET_MNEMONIC';
const CREATE_WALLET: string = 'CREATE_WALLET';
const GENERATE_MNEMONIC_PHRASE: string = 'GENERATE_MNEMONIC_PHRASE';
const VALIDATE_MNEMONIC_DATA: string = 'VALIDATE_MNEMONIC_DATA';
const SIGN_TRANSACTION: string = 'SIGN_TRANSACTION';
const SET_TO_ADDRESS: string = 'SET_TO_ADDRESS';
const SET_TRANSFER_TOKEN: string = 'SET_TRANSFER_TOKEN';
const SET_TRANSFER_AMOUNT: string = 'SET_TRANSFER_AMOUNT';
const SET_NODE_URL: string = 'SET_NODE_URL';
const SET_TOKEN_FACTORY_ADDRESS: string = 'SET_TOKEN_FACTORY_ADDRESS';
const SET_PUBLIC_KEY: string = 'SET_PUBLIC_KEY';
const SET_MNEMONIC: string = 'SET_MNEMONIC';

export {
  GET_MNEMONIC,
  CREATE_WALLET,
  GENERATE_MNEMONIC_PHRASE,
  VALIDATE_MNEMONIC_DATA,
  SIGN_TRANSACTION,
  SET_TO_ADDRESS,
  SET_TRANSFER_TOKEN,
  SET_TRANSFER_AMOUNT,
  SET_NODE_URL,
  SET_TOKEN_FACTORY_ADDRESS,
  SET_PUBLIC_KEY,
  SET_MNEMONIC,
};
